html,
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

:root {
    --primary-color: #545454;
    --form-label-color: #000000;
    --secondary-color: #082F65;
    --border-input: #807F80;
    --placeholder-color: #656565;
    --actions-color: #0E4FA8;
    --invalid-content: #e24c4c;
}

button,
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link,
.p-dialog,
input,
textarea,
.p-toast,
.p-table,
th,
td,
.p-dropdown,
.p-dropdown-label ,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-tabmenu,
.p-breadcrumb {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}

button {
    border: none;
}

input:enabled:focus{
    box-shadow: unset !important;
}

button:enabled:focus{
    box-shadow: unset !important;
}

.container-module {
    padding: 25px 35px 0px 35px;

    .title-module {
        font-size: 32px;
        font-weight: 700;
        color: var(--primary-color);
    }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: var(--primary-color);
    background-color: #ffffff;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 14px;
}

.p-dropdown-label {
    font-size: 14px;
}

.input-filters {
    height: 44px;
    font-size: 14px;
    border: 1px solid var(--border-input);
    padding-left: 40px;
}

.input-filters::placeholder {
    color: var(--placeholder-color);
}

.dropdown-filter .p-dropdown {
    height: 44px;
    border: 1px solid var(--border-input);
    padding-left: 30px;
}

.dropdown-filter .p-dropdown .p-dropdown-label.p-placeholder {
    color: var(--placeholder-color);
    font-size: 14px;
}

.calendar-filter .p-calendar {
  height: 44px;
  border: 1px solid var(--border-input);
  border-radius: 6px;
  padding-left: 30px;
  width: 250px;
  .p-inputtext {
    border: none;
  }
}

.calendar-filter .p-calendar .p-inputtext.p-placeholder {
  color: var(--placeholder-color);
  font-size: 14px;
}

.button-action-module {
    background-color: var(--form-label-color);
    font-weight: 600;
    padding: 10px 20px 10px 20px;
    color: #ffffff;
    border-radius: 8px;
}

.p-dialog .p-dialog-header {
    color: var(--form-label-color);
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: var(--form-label-color);
}

.subtitle-modal {
    color:  #000000;
}

.secondary-label {
    color: #1B1B1A;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #686667;
    border-radius: 3px;
    box-shadow: unset;
}

.input-form {
    padding-left: 12px;
}

.button-action-modal {
    padding: 10px 60px 10px 60px;
}

.p-datatable .p-datatable-thead > tr > th {
    background-color: #f5f5f5;
    color: black;
    font-weight: 600;
    padding: 0.5rem 1rem;
}

.border-last-column {
    border-radius: 0px 6px 0px 0px;
}

.border-first-column {
    border-radius: 6px 0px 0px 0px;
}

.p-datatable .p-datatable-tbody > tr > td {
    color: #333333;
}

.icons-action-table {
    color: var(--actions-color);
}

.p-breadcrumb {
    border: none;
    padding: 1rem 1rem 1rem 0rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    border-color: var(--actions-color);
    color: var(--actions-color);
    font-weight: 600;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    font-weight: 400;
    color: #000000;
}

.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
}

.p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
    font-weight: 600;
    color: var(--actions-color);
}

.p-menuitem-icon {
    font-size: 17px;
    color: #000000;
}

.p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
    color: #000000;
    margin: 0 0.9rem 0 0.9rem;
}

.border-input-invalid {
    border: 1px solid var(--invalid-content);
}

.color-message-invalid {
    color: var(--invalid-content);
}

.border-dropdown-invalid .p-dropdown {
    border: 1px solid var(--invalid-content);
}

.actions-column {
    width: 92px;
}
